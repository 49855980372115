;
$(function () {
  'use strict'

  $('#main_news_close').on('click', function (event) {
    event.stopPropagation()

    var $this = $(this)

    $this.closest('.main_news').fadeOut(500, function () {
      $(this).remove()
    })

    window.set_cookie('main_news_closed', $this.data('id'), 14)
  })
})
